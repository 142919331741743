<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {swalHelper} from "@/helpers/swal-helper";
import {languageHelper} from "@/helpers/language-helper";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Markets Component
 */
export default {

  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      filtering: {
        deleted: "false"
      },

      modals: {
        market: {
          visible: false,

          form: {
            imageUrl: "",
            currency: "",
            countryCode: ""
          }
        }
      },

      imageFile: null,
      imageExtension: "",
      translationValues: null
    };
  },

  validations() {
    if (this.modals.market.form.id) {
      return {
        modals: {
          market: {
            form: {
              currency: {required}
            }
          }
        },

        imageFile: {required}
      }
    }

    return {
      modals: {
        market: {
          form: {
            currency: {required},
            countryCode: {required}
          }
        }
      },

      imageFile: {required}
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.markets.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "image", slot: true, label: "Flaga"},
        {key: "i18nTranslation", label: "Nazwa", formatter: value => this.$t(value)},
        {key: "currency", label: "Waluta"},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadMarkets() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/market/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage,
            ...this.filtering
          }
        });

        this.table.items = data.markets
        this.table.totalRows = data.count;
        this.table.rows = data.count;

        if (this.mounted) {
          this.$refs.table.refresh()
        }

        return this.table.items;
      } catch (error) {
        errorCatcher.catchErrors(error)
        return []
      }
    },

    async openMarketModal(market) {
      await this.loadAllLanguages()
      this.translationValues = Object.fromEntries(
          [...this.allLanguages.map((obj) => [obj.countryCodeISO639_1, ""])]
      );

      if (market) {
        this.modals.market.form = Object.assign({}, market)

        for (const language of this.allLanguages) {
          await this.loadTranslations(language)
        }
      }

      this.modals.market.visible = true
    },

    deleteMarket(market) {
      swalHelper.yesOrNoCustomizable(() => {
        const obj = Object.assign({}, market)
        obj.deleted = true

        const json = JSON.stringify(obj);
        axios.put(`/market`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          Swal.fire("Sukces!", "Rynek został usunięty!", "success")
              .then(() => this.$refs.table.refresh())
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })

      }, "warning", "Jesteś pewien?", `Rynek zostanie usunięty`, "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    async loadAllLanguages() {
      try {
        const {data} = await axios.get(`/language`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.allLanguages = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadTranslations(language) {
      try {
        const {data} = await axios.get(`/translation/by-language-id/${language.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const translations = languageHelper.transformJSONToMap(JSON.parse(data.translations))
        const translation = translations[this.modals.market.form.i18nTranslation]
        if (translation) {
          this.translationValues[language.countryCodeISO639_1] = translation
        }

      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getLanguage(iso) {
      for (const language of this.allLanguages) {
        if (language.countryCodeISO639_1 === iso) {
          return language
        }
      }

      return null
    },

    hideMarketModal() {
      this.modals.market.visible = false
      this.modals.market.form.currency = ""
      this.modals.market.form.countryCode = ""
      this.modals.market.form.imageUrl = ""
      this.modals.market.form.id = ""
    },

    async createOrEditMarket() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.modals.market.form.$touch();
      if (this.$v.modals.market.form.$invalid) {
        return;
      }

      if (this.translationValues) {
        for (const key in this.translationValues) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.translationValues.hasOwnProperty(key)) {
            const value = this.translationValues[key];
            if (!value) {
              return;
            }
          }
        }
      }

      await this.updateTranslations()

      if (this.imageFile) {
        await this.upload()
      }

      let obj = {i18nTranslation: `market.ecat.${this.modals.market.form.countryCode}`}
      if (this.modals.market.form.id) {
        obj = {}
      }

      const json = JSON.stringify({
        ...this.modals.market.form,
        ...obj
      });

      axios.put(`/market`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.hideMarketModal()
        Swal.fire("Sukces!", this.modals.market.form.id ? "Zaktualizowano rynek" : "Dodano nowy rynek", "success")
            .then(() => this.$refs.table.refresh())
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    async updateTranslations() {
      for (const key in this.translationValues) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.translationValues.hasOwnProperty(key)) {
          const value = this.translationValues[key];
          await this.findTranslation(key, value)
        }
      }
    },

    async findTranslation(key, value) {
      const language = this.getLanguage(key)
      if (!language) {
        return
      }

      try {
        const {data} = await axios.get(`/translation/by-language-id/${language.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.updateTranslation(data.id, value)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async updateTranslation(id, value) {
      const translationsToCreateOrUpdate = {}
      if (this.modals.market.form.id) {
        translationsToCreateOrUpdate[this.modals.market.form.i18nTranslation] = value
      } else {
        translationsToCreateOrUpdate[`ecat.market.${this.modals.market.form.countryCode}`] = value
      }

      const json = JSON.stringify({
        "id": id,
        "translationsToCreateOrUpdate": translationsToCreateOrUpdate,
        "translationsToRemove": []
      })

      try {
        await axios.post(`/translation`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        });
      } catch (error) {
        errorCatcher.catchErrors(error);
      }
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.imageFile);
      formData.append('extension', this.imageExtension)

      try {
        const {data} = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.modals.market.form.imageUrl = data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    uploadImage(input) {
      const image = input.target.files[0];
      this.imageFile = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        const dataUrl = event.target.result;
        this.imageExtension = dataUrl.split(';')[0].split('/')[1];
      };
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.markets.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <b-button variant="success" @click="openMarketModal(null)">Dodaj nowy rynek</b-button>
              </div>
            </div>
            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-xl-12 nopadding">
                <div class="col-xl-3">
                  <vue-multiselect
                      v-model="filtering.deleted"
                      @select="setToFirstPageAndRefresh"
                      placeholder="Wybierz typ filtracji"
                      label="Pokazuj rynki:"
                      :options="[ 'true', 'false' ]"
                      :custom-label="value => value === 'false' ? 'Aktywne' : 'Usunięte'"
                      :show-labels="false"
                  />
                </div>
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadMarkets"
                :pagination-top="true"

            >
              <template v-slot:image="{ item }">
                <img :src="item.imageUrl" alt="Flag Icon" class="avatar-sm">
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items">
                  <b-button variant="primary" class="btn-sm" @click="openMarketModal(item)">Edytuj</b-button>
                  <b-button variant="danger" class="btn-sm" @click="deleteMarket(item)">Usuń</b-button>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.market.visible"
        :title="modals.market.form.id ? 'Edytowanie rynku' : 'Dodawanie nowego rynku'"
        title-class="font-18"
        hide-footer
        @hide="hideMarketModal"
        @esc="hideMarketModal">
      <div class="form-group">
        <label for="image">Flaga</label>
        <br>

        <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="image" @change="uploadImage"
               :class="{ 'is-invalid': $v.imageFile.$error }">
        <div v-if="!$v.imageFile.required" class="invalid-feedback">{{ $t('message.required') }}</div>

        <img :src="modals.market.form.imageUrl" alt="Flag Image" class="rounded avatar-lg">
      </div>

      <div class="form-group" v-if="!modals.market.form.id">
        <label>Kod kraju</label>
        <input v-model="modals.market.form.countryCode" type="text" class="form-control"
               :class="{ 'is-invalid': submitted && $v.modals.market.form.countryCode.$error }"/>
        <div v-if="!$v.modals.market.form.countryCode.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="row mb-2" v-for="(value, key) in translationValues" :key="key">
        <div class="col-2 py-2">
          <b>{{ key.toUpperCase() }}</b>:
        </div>
        <div class="col-10">
          <input v-model="translationValues[key]" type="text" class="form-control"
                 :class="{ 'is-invalid': submitted && !translationValues[key] }"/>
          <div v-if="submitted && !translationValues[key]" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>
      </div>

      <div class="form-group">
        <ecat-multiselect
            :set-value="modals.market.form.currency"
            @change="value => modals.market.form.currency = value"
            view-id="currency"
            save-id="currency"
            :can-unselect="true"
            label="Waluta"
            placeholder="Wybierz walutę"
            fetch-one-url="/exchange-rates"
            load-url="/exchange-rates/pagination"
            query-url="/exchange-rates/by-currency"
            param="currency"
            :class="{ 'is-invalid': submitted && $v.modals.market.form.currency.$error }"
        />
        <div v-if="!$v.modals.market.form.currency.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="text-right">
        <b-button @click="createOrEditMarket" variant="success">{{
            modals.market.form.id ? 'Edytuj' : 'Utwórz'
          }}
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideMarketModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>